import {
  Box,
  Container,
  Header,
  KeyValuePairs,
  Link,
} from "@amzn/awsui-components-react";
import { InternalLink } from "../internal-link";
import { SeverityBadge } from "../severity-badge/SeverityBadge";
import { isAdmin } from "../../helpers/isAdmin";

export const ComplianceAlertContainer = ({ alert, count, usersCount }) => {
  const items = [
    {
      label: "Alerts",
      value: (
        <InternalLink
          variant="awsui-value-large"
          href={`/bypasses/all#${alert?.id}`}
        >
          {count}
        </InternalLink>
      ),
    },
  ];

  if (isAdmin()) {
    items.push({
      label: "Users with this alert",
      value: (
        <Box fontSize="display-l" fontWeight="bold">
          {usersCount}
        </Box>
      ),
    });
  }

  return (
    <Container
      fitHeight
      header={
        <Header
          variant="h2"
          info={
            <Link variant="info" href={alert?.mitigationLink}>
              Mitigation
            </Link>
          }
          description={alert?.description || "-"}
          actions={<SeverityBadge severity={alert?.severityLevel} />}
          data-testid={`alert-header-${alert?.id}`}
        >
          {alert?.title || "-"}
        </Header>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          height: "100%",
        }}
      >
        <div data-testid={`alert-count-${alert?.id}`}>
          <KeyValuePairs columns={2} items={items}></KeyValuePairs>
        </div>
      </div>
    </Container>
  );
};
