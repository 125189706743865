import { AttributeEditor, BreadcrumbGroup, Button, Container, Form, FormField, Header, Input, SpaceBetween, Tiles } from "@amzn/awsui-components-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FlashbarPublisher } from "../../FlashbarPublisher";

export const EditRepo = ({ ruleConfig }) => {
    const AdditionalContributorsSelection = {
        SELECTED: 'selected',
        NOT_SELECTED: 'not_selected'
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    const [ownersErrorText, setOwnersErrorText] = useState("");
    const [additionalContributorsErrorText, setAdditionalContributorsErrorText] = useState("");
    const [additionalContributors, setAdditionalContributors] = useState(ruleConfig.additionalContributors?.map(contributor => contributor.username) || []);
    const [owners, setOwners] = useState(ruleConfig.owners?.map(owner => owner.username) || []);
    const [additionalContributorsSelection, setAdditionalContributorsSelection] = useState(ruleConfig.additionalContributors?.length > 0 ? AdditionalContributorsSelection.SELECTED : AdditionalContributorsSelection.NOT_SELECTED);
    const navigate = useNavigate();

    const handleCancel = () => {
        window.location.hash = "";
    }

    const handleSubmit = async () => {
        // Preliminary client error checks
        let hasErrors = false;

        if (owners.length === 0) {
            setOwnersErrorText("Please add at least one owner.");
            hasErrors = true;
        } else if (owners.some(owner => owner === "")) {
            setOwnersErrorText("Owner alias cannot be empty. Please add an alias or remove the entry.");
            hasErrors = true;
        }
        if (additionalContributors.some(contributor => contributor === "")) {
            setAdditionalContributorsErrorText("Contributor alias cannot be empty.")
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            setSubmitLoading(true);
            await new Promise(e => setTimeout(e, 1e3));
            window.location.hash = "";
            FlashbarPublisher.setItems([{
                id: "edit_repo_success",
                header: "Repository rule changes saved.",
                type: "success",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "It may take some time to propogate your changes to all client machines depending on their internet connections.",
            }]);
        } catch (error) {
            FlashbarPublisher.setItems([{
                id: "edit_repo_failed",
                header: "Repository edit failed.",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "There was an error modifying your repository rule configuration.",
            }]);
        }
        setSubmitLoading(false);
    }

    return (
        <SpaceBetween direction='vertical' size='s'>
            <BreadcrumbGroup
                test-id="breadcrumb-group"
                items={[
                    { text: "Code Defender", href: "/" },
                    { text: "Repository Rules", href: "/repo_rules" },
                    { text: ruleConfig.url, href: `/repo_rules/${ruleConfig.id}` },
                    { text: "Edit repository", href: `/repo_rules${ruleConfig.id}` }
                ]}
                ariaLabel="Breadcrumbs"
                onClick={(ev) => {
                    ev.preventDefault();
                    navigate(ev.detail.href);
                }}
            />
            <form onSubmit={e => e.preventDefault()}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button formAction="none" onClick={handleCancel} variant="link">
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleSubmit} loading={submitLoading}>Save changes</Button>
                        </SpaceBetween>
                    }
                    header={
                        <Header
                            variant="h1"
                        >
                            Edit repository information
                        </Header>
                    }
                >

                    <SpaceBetween direction='vertical' size='l'>
                        <Container
                            header={
                                <Header variant="h2">
                                    Overview
                                </Header>
                            }
                        >
                            <SpaceBetween direction="vertical" size="l">
                                <FormField label="Repository URL">
                                    <Input
                                        disabled={true}
                                        value={ruleConfig.url}
                                    />
                                </FormField>
                                <FormField
                                    label="Repository owners"
                                    description="You can select owners under your management. For anyone outside your management chain please use the Delegate feature."
                                    errorText={ownersErrorText}
                                >
                                    <AttributeEditor
                                        onAddButtonClick={() => {
                                            setOwners([...owners, ""]);
                                            setOwnersErrorText("");
                                        }}
                                        onRemoveButtonClick={({
                                            detail: { itemIndex }
                                        }) => {
                                            const tmpItems = [...owners];
                                            tmpItems.splice(itemIndex, 1);
                                            setOwners(tmpItems);
                                            setOwnersErrorText("");
                                        }}
                                        items={owners}
                                        addButtonText="Add additional repository owner"
                                        removeButtonText="Remove"
                                        definition={[
                                            {
                                                control: (item, itemIndex) => (
                                                    <Input
                                                        type="search"
                                                        onChange={({ detail }) => {
                                                            const tmpItems = [...owners];
                                                            tmpItems[itemIndex] = detail.value;
                                                            setOwners(tmpItems);
                                                            setOwnersErrorText("");
                                                        }}
                                                        value={owners[itemIndex]}
                                                        placeholder="Type alias"
                                                    />
                                                )
                                            }
                                        ]}
                                    />
                                </FormField>
                            </SpaceBetween>
                        </Container>
                        <Container
                            header={
                                <Header variant="h2">
                                    Choose repository contributors
                                </Header>
                            }
                        >
                            <SpaceBetween direction="vertical" size="l">
                                Who will be contributing to this repository?
                                <Tiles
                                    onChange={({ detail }) => {
                                        setAdditionalContributorsSelection(detail.value);
                                        if (detail.value === AdditionalContributorsSelection.NOT_SELECTED) {
                                            setAdditionalContributors([]);
                                        } else {
                                            setAdditionalContributors([""]);
                                        }
                                    }}
                                    value={additionalContributorsSelection}
                                    items={[
                                        {
                                            label: "All repository contributors are under owner's management",
                                            description: "Everyone under owner's management will be automatically added.",
                                            value: AdditionalContributorsSelection.NOT_SELECTED
                                        },
                                        {
                                            label: "I want to add additional repository contributors on top of everyone under owner's management",
                                            description: "Add additional people that contribute to the repository but outside of owner's management chain.",
                                            value: AdditionalContributorsSelection.SELECTED
                                        }
                                    ]}
                                />
                                {additionalContributorsSelection === AdditionalContributorsSelection.SELECTED &&
                                    <FormField
                                        label="Additional repository contributors"
                                        errorText={additionalContributorsErrorText}
                                    >
                                        <AttributeEditor
                                            onAddButtonClick={() => setAdditionalContributors([...additionalContributors, ""])}
                                            onRemoveButtonClick={({
                                                detail: { itemIndex }
                                            }) => {
                                                if (additionalContributors.length === 1) {
                                                    setAdditionalContributorsSelection(AdditionalContributorsSelection.NOT_SELECTED);
                                                }

                                                const tmpItems = [...additionalContributors];
                                                tmpItems.splice(itemIndex, 1);
                                                setAdditionalContributors(tmpItems);
                                                setAdditionalContributorsErrorText("");
                                            }}
                                            items={additionalContributors}
                                            addButtonText="Add additional contributor"
                                            removeButtonText="Remove"
                                            definition={[
                                                {
                                                    control: (item, itemIndex) => (
                                                        <Input
                                                            type="search"
                                                            onChange={({ detail }) => {
                                                                const tmpItems = [...additionalContributors];
                                                                tmpItems[itemIndex] = detail.value;
                                                                setAdditionalContributors(tmpItems);
                                                                setAdditionalContributorsErrorText("");
                                                            }}
                                                            value={additionalContributors[itemIndex]}
                                                            placeholder="Type alias"

                                                        />
                                                    ),
                                                }
                                            ]}
                                        />
                                    </FormField>}
                            </SpaceBetween>
                        </Container>
                    </SpaceBetween>
                </Form>
            </form>
        </SpaceBetween >
    );
}