export function formatRiskGraphData(riskCounts) {
    let overallData = [], personalData = []
    if (riskCounts != null) {
        if (riskCounts.total.aggregated && hasDisplayableData(riskCounts.total.aggregated)) {
            const criticalDataset = formatData(riskCounts.critical.aggregated);
            const highDataset = formatData(riskCounts.high.aggregated);
            const mediumDataset = formatData(riskCounts.medium.aggregated);
            const lowDataset = formatData(riskCounts.low.aggregated);
            const totalDataset = formatData(riskCounts.total.aggregated);

            overallData = [
                { title: "Critical Risks", type: "line", data: criticalDataset },
                { title: "High Risks", type: "line", data: highDataset },
                { title: "Medium Risks", type: "line", data: mediumDataset },
                { title: "Low Risks", type: "line", data: lowDataset },
                { title: "Total Risks", type: "line", data: totalDataset },
            ];
        }

        if (riskCounts.total.personal && hasDisplayableData(riskCounts.total.personal)) {
            const criticalDataset = formatData(riskCounts.critical.personal);
            const highDataset = formatData(riskCounts.high.personal);
            const mediumDataset = formatData(riskCounts.medium.personal);
            const lowDataset = formatData(riskCounts.low.personal);
            const totalDataset = formatData(riskCounts.total.personal);

            personalData = [
                { title: "Critical Risks", type: "line", data: criticalDataset },
                { title: "High Risks", type: "line", data: highDataset },
                { title: "Medium Risks", type: "line", data: mediumDataset },
                { title: "Low Risks", type: "line", data: lowDataset },
                { title: "Total Risks", type: "line", data: totalDataset },
            ];
        }
    }

    return { overallData, personalData };
}

function formatData(dataset) {
    return dataset.map(elt => ({ x: new Date(elt.date), y: elt.count }))
        .sort((a, b) => a.x - b.x);
}

function hasDisplayableData(dataset) {
    for (const date of dataset) {
        if (date.count !== 0) {
            return true;
        }
    }

    return false
} 

