import {
  BreadcrumbGroup,
  Grid,
  Header,
  SpaceBetween,
  Spinner,
} from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getBypasses from "../../helpers/getBypasses";
import { Alerts } from "../bypasses-ui/Alerts";
import { FlashbarPublisher } from "../FlashbarPublisher";
import { ComplianceAlertContainer } from "./ComplianceAlertContainer";

export const ComplianceAlerts = () => {
  const [rightAfterBlockedCommitBypasses, setRightAfterBlockedCommitBypasses] =
    useState([]);
  const [recurringUnintentionalBypasses, setRecurringUnintentionalBypasses] =
    useState([]);
  const [
    missingReasonForThirtyPlusDaysBypasses,
    setMissingReasonForThirtyPlusDaysBypasses,
  ] = useState([]);
  const [markedAsIntentionalBypasses, setMarkedAsIntentionalBypasses] =
    useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchBypasses = async () => {
    try {
      setLoading(true);
      const bypasses = await getBypasses();
      setRightAfterBlockedCommitBypasses(
        bypasses.filter((bypass) =>
          bypass.tags?.some((tag) => tag === Alerts.RightAfterBlockedCommit.id)
        )
      );
      setRecurringUnintentionalBypasses(
        bypasses.filter((bypass) =>
          bypass.tags?.some((tag) => tag === Alerts.RecurringUnintentional.id)
        )
      );
      setMissingReasonForThirtyPlusDaysBypasses(
        bypasses.filter((bypass) =>
          bypass.tags?.some(
            (tag) => tag === Alerts.MissingReasonForThirtyPlusDays.id
          )
        )
      );
      setMarkedAsIntentionalBypasses(
        bypasses.filter((bypass) =>
          bypass.tags?.some((tag) => tag === Alerts.MarkedAsIntentional.id)
        )
      );
    } catch (e) {
      FlashbarPublisher.setItems([
        {
          id: "fetch_bypasses_error",
          type: "error",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: FlashbarPublisher.clear,
          content: "There was an error fetching your bypasses.",
        },
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBypasses();
  }, []);

  const getUserCount = (bypasses) => {
    const users = new Set();
    bypasses.forEach((bypass) => {
      users.add(bypass.userId);
    });
    return users.size;
  };

  return (
    <div data-testid={"bypasses"}>
      <SpaceBetween direction="vertical" size="s">
        <BreadcrumbGroup
          test-id="breadcrumb-group"
          items={[
            { text: "Code Defender", href: "/" },
            { text: "Compliance alerts", href: "/compliance_alerts" },
          ]}
          ariaLabel="Breadcrumbs"
          onClick={(ev) => {
            ev.preventDefault();
            navigate(ev.detail.href);
          }}
        />

        <Header
          variant="h1"
          description="Shows alerts from yourself from
              the last 90 days."
          counter={`(${
            rightAfterBlockedCommitBypasses.length +
            recurringUnintentionalBypasses.length +
            missingReasonForThirtyPlusDaysBypasses.length +
            markedAsIntentionalBypasses.length
          })`}
        >
          Compliance alerts
        </Header>
        {loading && <Spinner size="large" />}
        {!loading && (
          <Grid
            gridDefinition={[
              { colspan: 6 },
              { colspan: 6 },
              { colspan: 6 },
              { colspan: 6 },
            ]}
          >
            <ComplianceAlertContainer
              alert={Alerts.RightAfterBlockedCommit}
              count={rightAfterBlockedCommitBypasses.length}
              usersCount={getUserCount(rightAfterBlockedCommitBypasses)}
            />
            <ComplianceAlertContainer
              alert={Alerts.MarkedAsIntentional}
              count={markedAsIntentionalBypasses.length}
              usersCount={getUserCount(markedAsIntentionalBypasses)}
            />
            <ComplianceAlertContainer
              alert={Alerts.RecurringUnintentional}
              count={recurringUnintentionalBypasses.length}
              usersCount={getUserCount(recurringUnintentionalBypasses)}
            />
            <ComplianceAlertContainer
              alert={Alerts.MissingReasonForThirtyPlusDays}
              count={missingReasonForThirtyPlusDaysBypasses.length}
              usersCount={getUserCount(missingReasonForThirtyPlusDaysBypasses)}
            />
          </Grid>
        )}
      </SpaceBetween>
    </div>
  );
};
