import {positiveIconBuilder, negativeIconBuilder} from './iconBuilders';

export function organizeMetrics(privateData, publicData) {
    let iconMetrics = ["failed_check_ins",
        "multiple_blocked_commits",
        "multiple_blocked_commits_secrets",
        "multiple_blocked_pushes"]
    let privateArray = []

    for (let metric of Object.keys(privateData.one)) {
        let metricTitle = caseConverter(metric)
        if (iconMetrics.includes(metric)) {
            privateArray.push(
                {
                    metric: metricTitle,
                    oneDay: privateData.one[metric] > 0 ? negativeIconBuilder(privateData.one[metric]) :
                        positiveIconBuilder(privateData.one[metric]),
                    sevenDays: privateData.seven[metric] > 0 ? negativeIconBuilder(privateData.seven[metric]) :
                        positiveIconBuilder(privateData.seven[metric]),
                    thirtyDays: privateData.thirty[metric] > 0 ? negativeIconBuilder(privateData.thirty[metric]) :
                        positiveIconBuilder(privateData.thirty[metric]),
                }
            )
        } else {
            privateArray.push(
                {
                    metric: metricTitle,
                    oneDay: privateData.one[metric],
                    sevenDays: privateData.seven[metric],
                    thirtyDays: privateData.thirty[metric],
                }
            )
        }
    }

    privateArray.splice(3, 0, {
        metric: "Bypass Attempts",
        oneDay: publicData.one.bypasses,
        sevenDays: publicData.seven.bypasses,
        thirtyDays: publicData.thirty.bypasses,
    })

    privateArray.splice(5, 0, {
        metric: "Total Pushes",
        oneDay: privateData.one["validated_pushes"] + privateData.one["blocked_pushes"],
        sevenDays: privateData.seven["validated_pushes"] + privateData.seven["blocked_pushes"],
        thirtyDays: privateData.thirty["validated_pushes"] + privateData.thirty["blocked_pushes"],
    })

    return privateArray
}

export function organizeBlockedCommits(publicData) {
    let commitsMetricName = [
        "commits_containing_secret_key",
        "commits_with_access_key",
        "commits_with_provider_output",
        "commits_with_allowed_secret",
        "commits_with_too_many_patterns",
        "commits_with_private_rsa_key",
    ]
    let commitArray = []
    let numBlockedCommits = 0

    for (let metric of Object.keys(publicData.one)) {
        let metricTitle = caseConverter(metric)
        if (commitsMetricName.includes(metric)) {
            commitArray.push(
                {
                    metric: metricTitle,
                    oneDay: publicData.one[metric] > 0 ? negativeIconBuilder(publicData.one[metric]) :
                        positiveIconBuilder(publicData.one[metric]),
                    sevenDays: publicData.seven[metric] > 0 ? negativeIconBuilder(publicData.seven[metric]) :
                        positiveIconBuilder(publicData.seven[metric]),
                    thirtyDays: publicData.thirty[metric] > 0 ? negativeIconBuilder(publicData.thirty[metric]) :
                        positiveIconBuilder(publicData.thirty[metric]),
                }
            )

            if (publicData.thirty[metric] > 0) {
                numBlockedCommits += publicData.thirty[metric]
            }
        }
    }

    return [commitArray, numBlockedCommits]
}

export function organizeUserIssueList(aggregated) {
    let issueMetricName = ["commits_with_secret_key_users",
        "commits_with_access_key_users",
        "commits_with_private_rsa_key_users",
        "multiple_bypasses_users",
        "multiple_failed_check_ins_users"]
    let issues = []

    issueMetricName.forEach(metric => {
        let metricTitle = caseConverter(metric);
        issues.push({
            metric: `${metric}`,
            metricTitle: metricTitle,
            userList: []
        });
    });

    // Loop through the metrics and update the user list for matching metrics
    Object.keys(aggregated.public.thirty).forEach(metric => {
        if (aggregated.public.thirty[metric] && aggregated.public.thirty[metric].length > 0 && issueMetricName.includes(metric)) {
            issues.forEach((issue, i) => {
                if (issue.metric === metric) {
                    issues[i] = {
                        metric: issue.metric,
                        metricTitle: issue.metricTitle,
                        userList: aggregated.public.thirty[metric]
                    };
                }
            });
        }
    });

    return issues
}

function caseConverter(inputeData) {
    return inputeData.split('_')
        .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ')
}


