import { DataAccessService } from "../core/api";
import { User } from "../core/user";

export default async function sendReasons(bypasses, reason, details) {
  try {
    const client = new DataAccessService();

    const requestBody = {
      commitMetadata: bypasses.map((bypass) => ({
        timestamp: bypass.time,
        commitId: bypass.commitId,
      })),
      noVerifyReasonType: reason,
      noVerifyReasonDetails: details,
    };
    const requestParams = {
      username: new User().userId,
    };
    await client.patch(`/metrics/bypass`, requestBody, requestParams);
  } catch (err) {
    console.warn(err);
    throw err;
  }
}
