import {
  Alert,
  Box,
  Button,
  Form,
  Modal,
  SpaceBetween,
} from "@amzn/awsui-components-react";

import { useState } from "react";
import { FlashbarPublisher } from "../FlashbarPublisher";
import dismissBypasses from "../../helpers/dismissBypasses";

export const DismissBypassModal = ({
  isVisible,
  setIsVisible,
  refreshBypasses,
  selectedItems,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalError, setModalError] = useState(false);

  const resetFormAndHideModal = () => {
    setIsVisible(false);
    setModalError(false);
  };

  const dismiss = async () => {
    try {
      setLoading(true);
      await dismissBypasses(selectedItems);
      resetFormAndHideModal();
      FlashbarPublisher.setItems([
        {
          id: "dismiss_success",
          type: "success",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: FlashbarPublisher.clear,
          content: `${selectedItems.length} bypass${
            selectedItems.length > 1 ? "es" : ""
          } dismissed.`,
        },
      ]);
      await refreshBypasses();
    } catch (e) {
      setModalError(true);
    }
    setLoading(false);
  };

  return (
    <Modal
      data-testid="dismiss-bypass-modal"
      onDismiss={() => resetFormAndHideModal()}
      visible={isVisible}
      header={`Dismiss bypass${selectedItems.length !== 1 ? "es" : ""}?`}
      footer={
        <SpaceBetween direction="vertical" size="xs">
          {modalError && (
            <Alert
              statusIconAriaLabel="Error"
              type="error"
              header={`There was an error dismissing your bypass${
                selectedItems.length !== 1 ? "es" : ""
              }.`}
            >
              Please try again later.
            </Alert>
          )}
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-testid={"cancel-dismiss-bypass-modal-button"}
                variant="link"
                onClick={() => resetFormAndHideModal()}
              >
                Cancel
              </Button>
              <Button
                data-testid={"submit-dismiss-bypass-modal-button"}
                variant="primary"
                onClick={dismiss}
                loading={loading}
              >
                Dismiss bypass{selectedItems.length !== 1 ? "es" : ""}
              </Button>
            </SpaceBetween>
          </Box>
        </SpaceBetween>
      }
    >
      <form onSubmit={(event) => event.preventDefault()}>
        <Form>
          <SpaceBetween size="l" direction="vertical">
            <div>
              Permanently dismiss{" "}
              <strong>
                {selectedItems.length} bypass
                {selectedItems.length !== 1 ? "es" : ""}?{" "}
              </strong>
              You can't undo this action.
            </div>
            <Alert statusIconAriaLabel="Warning" type="warning">
              Bypasses mean that commits are not protected by Code Defender for
              this commit. Make sure that you understand the unique situations
              and mitigate potential risks associated with these bypasses.
            </Alert>
          </SpaceBetween>
        </Form>
      </form>
    </Modal>
  );
};
