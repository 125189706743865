import { LocalStorage } from "../core/storage";
import { FEATURE_FLAGS } from "../core/user/user";

export const isBypassesPhase1On = () => {
    let isBypassesPhase1Enabled = false;

    const featureFlags = LocalStorage.get(FEATURE_FLAGS);
    if (featureFlags) {
        isBypassesPhase1Enabled = featureFlags['bypasses_phase_1'];
    }

    return isBypassesPhase1Enabled;
}