// Polaris
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Box,
    Button,
    Header,
    Link,
    Pagination,
    SpaceBetween,
    StatusIndicator,
    Table,
    TextFilter
} from "@amzn/awsui-components-react";
import * as _ from "lodash";
import { useEffect, useState } from "react";
import { renderedRepoType } from "../../core/constants";
import { User } from "../../core/user";
import { isTopRopeFeatureOn } from "../../helpers/isTopRopeFlagOn";
import {
    getRequests,
    RepoRequstStatus,
} from "../../helpers/repos/repoRequests";
import { ApproveRequestModal } from "./ApproveRequestModal";
import { RejectRepoModal } from "./RejectRepoModal";

export const RepositoryRequestTable = ({
  isAdmin,
  setSearch,
  search,
  managers,
}) => {
  const getMatchesCountText = (count) => {
    return count === 1 ? `1 match` : `${count} matches`;
  };

  const makeFlashbarListContentFromRequests = (message, requests) => {
    return (
      <>
        {message}
        <ul>
          {requests.map((item, index) => {
            return <li>{item.url}</li>;
          })}
        </ul>
      </>
    );
  };

  const getRepoRequests = async () => {
    setSelectedRequests([]);
    setLoading(true);

    // Request to backend for repo requests
    try {
      let response = await getRequests();
      setRepoRequests(_.uniqBy(response.requests, "id"));
    } catch (err) {
      // TODO: Display flash bar error (after Kage's refactor)
    }

    setLoading(false);
  };

  const getRenderedStatus = (status) => {
    if (status === RepoRequstStatus.PENDING) {
      return <StatusIndicator type="pending">Pending</StatusIndicator>;
    } else if (status === RepoRequstStatus.APPROVED) {
      return <StatusIndicator>Approved</StatusIndicator>;
    } else if (status === RepoRequstStatus.REJECTED) {
      return <StatusIndicator type="error">Rejected</StatusIndicator>;
    }
  };

  const sortingFunction = (a, b) => {
    // Pending statuses should go first
    if (
      a.status === RepoRequstStatus.PENDING &&
      b.status !== RepoRequstStatus.PENDING
    ) {
      return 1;
    } else if (
      a.status !== RepoRequstStatus.PENDING &&
      b.status === RepoRequstStatus.PENDING
    ) {
      return -1;
    }

    // Sort by timestamp if both are pending and last modified at if both are not pending
    if (
      a.status === RepoRequstStatus.PENDING &&
      b.status === RepoRequstStatus.PENDING
    ) {
      // Within 'PENDING', keep actionable requests on top
      if (user.userId === a.requester && user.userId !== b.requester) {
        return -1;
      } else if (user.userId !== a.requester && user.userId === b.requester) {
        return 1;
      }

      if (new Date(a.timestamp) < new Date(b.timestamp)) {
        return 1;
      } else {
        return -1;
      }
    } else {
      if (new Date(a.lastModifiedAt) < new Date(b.lastModifiedAt)) {
        return 1;
      } else {
        return -1;
      }
    }
  };

  // Get user for disabling checkboxes
  const user = new User();

  // State
  const [rejectRepoModalIsVisible, setRejectRepoModalIsVisible] =
    useState(false);
  const [approveRepoModalIsVisible, setApproveRepoModalIsVisible] =
    useState(false);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [repoRequests, setRepoRequests] = useState([]);
  const sortDescending = true;

  // List of table ids, also used for defining which attribute on item to sort and filter
  const url = "url";
  const reviewer = "reviewer";
  const requester = "requester";
  const timestamp = "timestamp";
  const rejectionReason = "rejectionReason";
  const lastUpdatedAt = "lastUpdatedAt";
  const status = "status";

  const statusSortingColumn = {
    id: status,
    header: "Status",
    cell: (item) => getRenderedStatus(item.status),
    sortingComparator: sortingFunction,
  };

  // Table collection
  const {
    items,
    collectionProps,
    filterProps,
    filteredItemsCount,
    paginationProps,
  } = useCollection(repoRequests, {
    pagination: {
      pageSize: 10,
    },
    // Controls which fields the search bar applies to
    // Cloudscape documentation for filtering and sorting: https://cloudscape.aws.dev/get-started/dev-guides/collection-hooks/
    filtering: {
      fields: [url],
    },
    sorting: {
      defaultState: {
        sortingColumn: statusSortingColumn,
        isDescending: sortDescending,
      },
    },
    selection: {},
  });

  useEffect(() => {
    getRepoRequests();
  }, []);

  return (
    <>
      <RejectRepoModal
        isVisible={rejectRepoModalIsVisible}
        setIsVisible={(isVisible) => setRejectRepoModalIsVisible(isVisible)}
        refreshRepoRequestList={getRepoRequests}
        makeFlashBar={makeFlashbarListContentFromRequests}
        selectedRequests={selectedRequests}
      />
      <ApproveRequestModal
        isVisible={approveRepoModalIsVisible}
        setIsVisible={(isVisible) => setApproveRepoModalIsVisible(isVisible)}
        refreshRepoRequestList={getRepoRequests}
        makeFlashBar={makeFlashbarListContentFromRequests}
        selectedRequests={selectedRequests}
        setSearch={setSearch}
        search={search}
        managers={managers}
      />
      <Table
        {...collectionProps}
        stickyColumns={{ first: 1, last: 0 }}
        header={
          <Header
            counter={`(${repoRequests.length})`}
            actions={
              isAdmin ? (
                <SpaceBetween direction="horizontal" size="s">
                  <Button
                    data-testid={"request-action-button"}
                    variant="normal"
                    disabled={selectedRequests.length === 0}
                    onClick={() => {
                      setRejectRepoModalIsVisible(true);
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    data-testid={"request-action-button"}
                    variant="primary"
                    disabled={
                      selectedRequests.length === 0 || !isTopRopeFeatureOn()
                    }
                    onClick={() => {
                      setApproveRepoModalIsVisible(true);
                    }}
                  >
                    Approve
                  </Button>
                </SpaceBetween>
              ) : (
                <></>
              )
            }
          >
            Repository requests
          </Header>
        }
        selectionType={isAdmin ? "single" : undefined}
        onSelectionChange={({ detail }) => {
          setSelectedRequests(detail.selectedItems);
        }}
        isItemDisabled={(item) => item.status !== RepoRequstStatus.PENDING}
        selectedItems={selectedRequests}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${
              selectedItems.length === 1 ? "item" : "items"
            } selected`,
          itemSelectionLabel: ({ selectedItems }, item) => item.name,
        }}
        items={items}
        loading={loading}
        loadingText="Loading repository requests"
        columnDefinitions={[
          {
            id: url,
            header: "Url",
            cell: (item) => (
              <Link external href={item.url}>
                {item.url}
              </Link>
            ),
            isRowHeader: true,
            sortingField: url,
          },
          {
            id: reviewer,
            header: "Reviewer",
            cell: (item) => (item.approver === "_" ? "N/A" : item.approver),
            sortingField: reviewer,
          },
          {
            id: "managerAlias",
            header: "Manager",
            cell: (item) => item.approvedFor,
            sortingField: "approvedFor",
          },
          {
            id: requester,
            header: "Requester",
            cell: (item) => item.requester,
            sortingField: requester,
          },
          {
            id: timestamp,
            header: "Requested date",
            cell: (item) => new Date(item.timestamp).toLocaleDateString(),
            sortingField: timestamp,
          },
          {
            id: "requestReason",
            header: "Request reason",
            cell: (item) => (
              <span title={renderedRepoType(item)}>
                {renderedRepoType(item)}
              </span>
            ),
            maxWidth: 200,
            sortingField: "reason",
          },
          {
            id: rejectionReason,
            header: "Reject reason",
            cell: (item) => (
              <span title={`${item.rejectionReason}`}>
                {item.rejectionReason}
              </span>
            ),
            maxWidth: 200,
            sortingField: rejectionReason,
          },
          statusSortingColumn,
          {
            id: lastUpdatedAt,
            header: "Last updated",
            cell: (item) =>
              item.lastUpdatedAt
                ? new Date(item.lastUpdatedAt).toLocaleDateString()
                : "N/A",
            sortingField: lastUpdatedAt,
          },
        ]}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find repository requests"
          />
        }
        pagination={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                iconName="refresh"
                variant="icon"
                disabled={loading}
                onClick={getRepoRequests}
              />
              <Pagination
                {...paginationProps}
                disabled={loading}
                openEnd={false}
              />
            </SpaceBetween>
          </Box>
        }
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <b>No requests</b>
          </Box>
        }
      />
    </>
  );
};
