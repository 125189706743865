import { DataAccessService } from "../core/api";
import { User } from "../core/user";

export default async function dismissBypasses(bypasses) {
  try {
    const client = new DataAccessService();
    const requestBody = bypasses.map((bypass) => ({
        userAlias: bypass.userAlias,
        chainLevelEpoch: bypass.chainLevelEpoch,
        commitId: bypass.commitId,
      }));
    const requestParams = {
      username: new User().userId,
    };
    await client.patch(`/metrics/bypass/dismiss`, requestBody, requestParams);
  } catch (err) {
    console.warn(err);
    throw err;
  }
}
