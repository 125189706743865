import { HelpPanel, Link } from "@amzn/awsui-components-react";
import ServiceAppLayout from "../../components/ServiceAppLayout";
import { UnacknowledgedBypasses } from "../../components/bypasses-ui/UnacknowledgedBypasses";

export const UnacknowledgedBypassesPage = () => {
    return (
        <ServiceAppLayout
            page="UnacknowledgedBypassesPage"
            content={
                <UnacknowledgedBypasses />
            }
            tools={<HelpPanel
                header={<h2>What's a bypass?</h2>}
                footer={
                    <div>
                        <Link
                            external
                            href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#29"
                            variant="primary"
                        >
                            I was flagged for a bypass, but I never attempted to do so. What do I do?
                        </Link>
                    </div>
                }
            >
                These commits have been recorded with the <code>--no-verify</code> flag as a bypass that skips Code Defender hooks.<br /><br /> These commits could contain sensitive values such as Secret Keys, Access Key IDs, etc, and are not under Code Defender's protection.
            </HelpPanel>
            }
            toolsHide={false}
        />
    )
};
