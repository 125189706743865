//React
import React from "react";

// Polaris
import {
    Box,
    ColumnLayout,
    Container,
    Header,
    Link,
    SpaceBetween,
    Spinner,
    Table,
} from '@amzn/awsui-components-react';

import { totalRisks } from 'Helper/riskMetrics';
import { useOutletContext } from "react-router-dom";

const ValueWithLabel = ({ label, children }) => (
    <SpaceBetween direction="vertical" size="s">
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </SpaceBetween>
)

export const personalColumnDefinitions = [
    {
        id: "metric",
        header: "Metric",
        cell: item => item.metric || "0"
    },
    {
        id: "oneDay",
        header: "One day",
        cell: item => item.oneDay || "0"
    },
    {
        id: "sevenDays",
        header: "Seven days",
        cell: item => item.sevenDays || "0"
    },
    {
        id: "thirtyDays",
        header: "Thirty days",
        cell: item => item.thirtyDays || "0"
    }
]

export const Personal = () => {

    const {
        loading,
        personalCommitItems,
        personalInstallStatus,
        personalItems,
        riskData,
        selectedUser,
    } = useOutletContext();

    return (<ColumnLayout>
        <ColumnLayout columns={2}>
            {selectedUser !== "ROOT" &&
                <Container header={
                    <Header
                        variant="h2"
                        description="Updates once a day"
                    >
                        Personal Status for {selectedUser}
                    </Header>
                }>
                    <ColumnLayout borders="vertical" columns={1}>
                        <ValueWithLabel label="Risk Status">
                            {loading ? <Spinner size="large" /> : totalRisks(riskData)}
                        </ValueWithLabel>
                    </ColumnLayout>
                </Container>
            }
        </ColumnLayout>

        {selectedUser !== "ROOT" &&
            <Container disableContentPaddings={true}>
                <Table
                    columnDefinitions={personalColumnDefinitions}
                    items={personalItems}
                    loadingText="Loading data"
                    loading={loading}
                    empty={<div>No data</div>}
                    header={
                        <Header
                            info={<Link external variant="info"
                                href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#20">Info</Link>}>
                            Personal Stats for {selectedUser}
                        </Header>}
                />
            </Container>
        }

        {selectedUser !== "ROOT" &&
            <Container disableContentPaddings={true}>
                <Table
                    columnDefinitions={personalColumnDefinitions}
                    items={personalCommitItems}
                    loadingText="Loading data"
                    loading={loading}
                    empty={<div>No data</div>}
                    header={
                        <div>
                            <Header
                                info={<Link external variant="info"
                                    href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#20">Info</Link>}
                            >
                                Personal Blocked Commit Breakdown for {selectedUser}
                            </Header>
                        </div>}
                />
            </Container>
        }
    </ColumnLayout>);
}