let devWebConfig = {
    region: 'us-east-1',
    userPoolId: 'us-east-1_RwyN3PTVj',
    userPoolWebClientId: 's88rtmnqmc5t5q7u7k2id2gnu',
    account: '669474806906',
    domain: 'defender.monkslc.people.aws.dev',
    cognitoDomain: 'Defender-669474806906.auth.us-east-1.amazoncognito.com'
}

export default devWebConfig
